import React, { useContext, useEffect, useRef, useState } from 'react'
import { Image, Box, Button, Flex } from 'rebass'
import { Label } from '@rebass/forms'
import {
  trackHandler,
  UneeqContext,
  useUneeqSpaceToTalk,
  useUneeqState
} from 'uneeq-react-core'
import { ReactComponent as ChatIcon } from '../../assets/img/conversation.svg'
import { ReactComponent as HistoryMode } from '../../assets/img/history-mode.svg'
import AnimatedFeedback from '../AnimatedFeedback/AnimatedFeedback'
import InputProblem from '../InputProblem'
import PushToTalk from './PushToTalk'
import styles from './styles'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { generateFilter } from 'colorize-filter'
import BackButton from './BackButton'
import Config from 'digital-human/src/config'
import { useMediaQuery } from 'react-responsive'
import NextButton from './NextButton'

interface ModalityToggleProps {
  isDisabled: boolean
  isChatEnabled?: boolean
  speechMode: boolean
  modalityToggleMode: () => void
}

export const ModalityToggle: React.FC<ModalityToggleProps> = ({
  speechMode,
  modalityToggleMode
}) => {
  const config = Config.getInstance()
  const iconChatStyle = speechMode
    ? config.persona.style.colors.icons.chat.basic
    : config.persona.style.colors.icons.chat.active
  return (
    <Button
      title="Conversation view"
      sx={{
        ...styles.bottomBarButton,
        ...iconChatStyle,
        pl: '0px',
        ml: config.persona.features.micEnabled ? '1rem' : '',
        pr: '0px',
        width: '4rem',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
          mt: '0.625rem',
          height: '2rem',
          width: '2rem'
        },
        boxShadow: 'none !important'
      }}
      onClick={modalityToggleMode}
    >
      <ChatIcon title="Conversation view" />
    </Button>
  )
}

const BottomBar = () => {
  const {
    recording,
    sending,
    startRecording,
    stopRecording
  } = useUneeqSpaceToTalk()

  // Avoid spamming the server with requests
  const stopRecordingAndSendAction = () =>
    state.recording && !state.avatarSpeaking && !state.sending
      ? stopRecording
      : null
  const startRecordingAction = () =>
    !state.recording && !state.avatarSpeaking && !state.sending
      ? startRecording
      : null

  let {
    inputMode,
    onScreenInfo,
    spacebarTapped,
    noInput,
    question,
    avatarSpeaking,
    galleryOpen
  } = useUneeqState()

  const { state } = useContext(UneeqContext)

  const isDisabled = () =>
    state.recording || state.avatarSpeaking || state.sending

  const { dispatch, config, sendEvent } = useContext(UneeqContext)
  const send = (response: string) => {
    sendEvent(response)
  }
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const speechMode = inputMode === 'speech'
  const isChatEnabled = !speechMode && mediaQueryMobile

  const toggleModalityMode = () => {
    if (speechMode) {
      dispatch({ type: 'setInputMode', payload: 'text' })
      dispatch({ type: 'closeGallery' })
      openTranscript()
    } else {
      dispatch({ type: 'setInputMode', payload: 'speech' })
      closeTranscript()
    }
  }

  const openTranscript = () => {
    dispatch({ type: 'openTranscript', payload: true })
    dispatch({ type: 'closeGallery' })
  }
  const closeTranscript = () => dispatch({ type: 'closeTranscript' })
  const inputError = spacebarTapped ? 'spacebarTapped' : noInput && 'noInput'

  let shouldShowQuestion =
    question && !onScreenInfo?.suggestedResponses?.chosenResponse

  interface Response {
    label: string
    utterance: string
    event: string
    hasImage: string
  }
  const [backButtonActive, setBackButtonActive] = useState(false)
  const [nextButtonActive, setNextButtonActive] = useState(false)
  useEffect(() => {
    let responses: Response[] =
      onScreenInfo &&
      Object.getPrototypeOf(onScreenInfo) === Object.prototype &&
      onScreenInfo.information
        ? onScreenInfo.information.length > 1
          ? Array.from(onScreenInfo.information)
          : onScreenInfo.information[0]?.buttons
          ? Array.from(onScreenInfo.information[0]?.buttons)
          : onScreenInfo.information
        : []
    let backButton = false
    let nextButton = false
    for (let response in responses) {
      if (
        responses[response]['label'] == 'GO_NEXT' ||
        responses[response]['GO_NEXT'] == 'true'
      ) {
        nextButton = true
      }
      if (
        responses[response]['label'] == 'GO_BACK' ||
        responses[response]['GO_BACK'] == 'true'
      ) {
        backButton = true
      }
    }
    setNextButtonActive(nextButton)
    setBackButtonActive(backButton)
  }, [onScreenInfo?.information])
  const inputModeContainer = useRef()
  useOnClickOutside(inputModeContainer, toggleModalityMode)
  const fullAvatarMode =
    onScreenInfo.information?.length < 1 ||
    onScreenInfo.information?.hasOwnProperty('event')
  return (
    <Flex sx={styles.bar}>
      <Flex sx={styles.left}>
        <BackButton
          fullAvatarMode={fullAvatarMode}
          onClick={() => {
            setBackButtonActive(false)
            setNextButtonActive(false)
            send('GO_BACK')
          }}
          buttonActive={backButtonActive}
        />
      </Flex>
      <Flex
        sx={{
          ...styles.center,
          alignItems: shouldShowQuestion || avatarSpeaking ? 'center' : 'normal'
        }}
      >
        {config.icons && (
          <Flex sx={styles.iconsBox}>
            {config.persona.style.icons.map(function(icon, i) {
              return (
                <Label sx={styles.iconItem} key={i}>
                  <a href={icon.link} target="_blank">
                    <Image
                      src={icon.iconURL}
                      style={{
                        filter: generateFilter(icon.color),
                        width: '1.563rem'
                      }}
                    />
                  </a>
                </Label>
              )
            })}
          </Flex>
        )}

        <Flex sx={styles.pttOuterContainer}>
          {config.persona.features?.buttonOnly ? (
            <Flex sx={styles.pttContainer}>
              <Box
                sx={{
                  ...styles.noInputButtonContainer,
                  '& > svg': {
                    fill: galleryOpen
                      ? config.persona.style.colors.galleryMode?.fill
                      : !speechMode
                      ? config.persona.style.colors.icons.chat.active.fill +
                        ' !important'
                      : config.persona.style.colors.icons.chat.basic.fill +
                        ' !important',
                    backgroundColor: galleryOpen
                      ? config.persona.style.colors.backgroundColor?.fill
                      : !speechMode
                      ? config.persona.style.colors.icons.chat.active
                          .backgroundColor + ' !important'
                      : config.persona.style.colors.icons.chat.basic
                          .backgroundColor + ' !important'
                  }
                }}
              >
                {!mediaQueryMobile && <AnimatedFeedback />}
              </Box>
              <Box
                sx={{
                  ...styles.noInputButtonContainer,
                  backgroundColor: galleryOpen
                    ? config.persona.style.colors.backgroundColor?.fill
                    : !speechMode
                    ? config.persona.style.colors.icons.chat.active
                        .backgroundColor + ' !important'
                    : config.persona.style.colors.icons.chat.basic
                        .backgroundColor + ' !important',
                  '& > svg': {
                    fill: galleryOpen
                      ? config.persona.style.colors.galleryMode?.fill
                      : !speechMode
                      ? config.persona.style.colors.icons.chat.active.fill +
                        ' !important'
                      : config.persona.style.colors.icons.chat.basic.fill +
                        ' !important'
                  }
                }}
                onClick={() => {
                  if (speechMode) {
                    dispatch({ type: 'setInputMode', payload: 'text' })
                    openTranscript()
                  } else {
                    dispatch({ type: 'setInputMode', payload: 'speech' })
                    closeTranscript()
                  }
                }}
              >
                <HistoryMode width="2rem" height="2rem" />
              </Box>
            </Flex>
          ) : (
            <Flex sx={styles.pttContainer}>
              {(!mediaQueryMobile || fullAvatarMode) && (
                <Box
                  sx={{
                    zIndex: 99,
                    ...styles.bottomBarButtonContainer,
                    '& > svg': {
                      mt: '0.313rem'
                    }
                  }}
                >
                  <AnimatedFeedback />
                </Box>
              )}
              {config.persona.features.micEnabled && (
                <Box
                  onTouchStart={startRecordingAction()}
                  onTouchEnd={stopRecordingAndSendAction()}
                  onMouseDown={startRecordingAction()}
                  onMouseUp={stopRecordingAndSendAction()}
                  sx={{
                    zIndex: 99,
                    ...styles.bottomBarButtonContainer
                  }}
                >
                  <PushToTalk
                    recording={recording}
                    sending={sending}
                    isDisabled={false}
                  />
                </Box>
              )}
              <Box sx={{ zIndex: 100, ...styles.bottomBarButtonContainer }}>
                {config.persona.name !== 'Diana' &&
                  !mediaQueryMobile &&
                  !isChatEnabled && (
                    <ModalityToggle
                      isDisabled={isDisabled()}
                      speechMode={speechMode}
                      isChatEnabled={isChatEnabled}
                      modalityToggleMode={trackHandler(
                        toggleModalityMode,
                        speechMode
                          ? 'enable-type-mode-btn'
                          : 'disable-type-mode-btn'
                      )}
                    />
                  )}
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex sx={styles.right}>
        <NextButton
          fullAvatarMode={fullAvatarMode}
          onClick={() => {
            setBackButtonActive(false)
            setNextButtonActive(false)
            send('GO_NEXT')
          }}
          buttonActive={nextButtonActive}
        />
      </Flex>
      {inputError && <InputProblem error={inputError} />}
    </Flex>
  )
}

export default BottomBar
