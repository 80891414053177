import { ReactComponent as BackIcon } from '../../assets/img/left-arrow.svg'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import React, { useContext } from 'react'
import { Button, Flex } from 'rebass'
import styles from './styles'
import Config from 'digital-human/src/config'
import { useMediaQuery } from 'react-responsive'

export interface Active {
  onClick: () => void
  fullAvatarMode: boolean
  buttonActive: boolean
}

const BackButton: React.FC<Active> = ({
  onClick,
  fullAvatarMode,
  buttonActive
}) => {
  const { state } = useContext(UneeqContext)
  const { galleryOpen } = useUneeqState()
  const blocked = state.recording || state.avatarSpeaking || state.sending
  const config = Config.getInstance()
  const mobileMediaQuery = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const svgSize = '2rem'
  const svgColor = config.persona.style.colors.buttons.arrows
    ? galleryOpen
      ? config?.persona.style.colors.galleryMode?.fill
      : mobileMediaQuery
      ? config.persona.style.colors.buttons.arrows.mobile.fill
      : config.persona.style.colors.buttons.arrows.basic.fill
    : 'white'
  return (
    <Flex>
      <Button
        sx={{
          ...styles.backButton,
          opacity: blocked ? '0.5' : '1',
          display: buttonActive ? 'block' : 'none',
          ':hover': {
            boxShadow: 'none',
            cursor: blocked ? 'pointer' : 'default'
          },
          '& > svg': {
            fill: svgColor
          }
        }}
        onClick={
          blocked
            ? () => {
                console.info('Button Blocked')
              }
            : onClick
        }
      >
        <BackIcon
          width={svgSize}
          height={svgSize}
          color={svgColor}
          fill={svgColor}
        />
      </Button>
    </Flex>
  )
}
export default BackButton
