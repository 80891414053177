import Config from 'digital-human/src/config'
import { useMediaQuery } from 'react-responsive'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex } from 'rebass'
import styles from './styles'
import { keyframes } from '@emotion/core'
import { ReactComponent as NextIcon } from '../../assets/img/rightarrow.svg'
export interface Active {
  onClick: () => void
  fullAvatarMode: boolean
  buttonActive: boolean
}

const NextButton: React.FC<Active> = ({
  onClick,
  fullAvatarMode,
  buttonActive
}) => {
  const { state } = useContext(UneeqContext)
  const blocked = state.recording || state.avatarSpeaking || state.sending
  const config = Config.getInstance()
  const mobileMediaQuery = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const svgSize = '2rem'

  const { galleryOpen, galleryIndex, imageGallery } = useUneeqState()
  const svgColor = galleryOpen
    ? config?.persona.style.colors.galleryMode?.fill
    : mobileMediaQuery
    ? config.persona.style.colors.buttons.arrows.mobile.fill
    : config.persona.style.colors.buttons.arrows.basic.fill
  const [blinking, setBlinking] = useState(false)
  const blink = () => keyframes`
    from {
        opacity: 1;
    }
  
    50% {
        opacity: 0.5;
    }
  
    to {
        opacity: 0;
    }
    `
  useEffect(() => {
    let timer
    if (blocked) {
      setBlinking(false)
    }
    if (!blocked) {
      timer = setTimeout(() => {
        if (!blocked) setBlinking(true)
      }, 3000)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [blocked, galleryIndex])
  return (
    <Flex>
      <Button
        sx={{
          ...styles.nextButton,
          background: 'none',
          animationName: blinking ? blink : 'none',
          animationDuration: blinking ? '1s' : 'none',
          animationIterationCount: blinking ? 'infinite' : 'none',
          opacity: blocked ? '0.5' : '1',
          display: buttonActive ? 'block' : 'none',
          ':hover': {
            boxShadow: 'none',
            cursor: !blocked ? 'pointer' : 'default'
          },
          '& > svg': {
            fill: svgColor
          }
        }}
        onClick={
          blocked
            ? () => {
                console.info('Button Blocked')
              }
            : () => {
                onClick()
                setBlinking(false)
              }
        }
      >
        <NextIcon width={svgSize} height={svgSize} color={svgColor} />
      </Button>
    </Flex>
  )
}

export default NextButton
