import testState from '../../utils/testState'
import { EventHandler } from '../../analytics'
import Config from 'digital-human/src/config'
const config = Config.getInstance()
const captionVisible = config.persona.features?.captions

export interface UneeqCorePersonaServer {
  id: string
  server: string
}

export interface UneeqCoreIcon {
  iconURL: string
  link: string
  target: string
  colors: string
}

export interface UneeqCorePersonaStyle {
  logo?: {
    svg?: string
    url?: string
    link?: string
  }
  caption: {
    toggle: boolean
  }
  colors: {
    primary: object
    icons: {
      mic: { basic: object; active: object }
      chat: { basic: object; active: object }
      settings: { basic: object; active: object }
    }
    bubble: {
      avatar: object
      user: object
    }
    buttons: {
      basic: object
      clicked: object
      active: object
      no_button?: object
      little_button?: object
      significant_button?: object
    }
  }
  // Should be removed in the Future because it will not use the homepage
  home: {
    background: {
      desktop: string
      mobile: string
    }
  }
  bottom: {
    icons: UneeqCoreIcon[]
  }
  checkIn: boolean
}

export interface UneeqCoreDialogflowEnvironment {
  name: string
  id: string
  isDefault?: boolean
}

export interface UneeqCoreDialogflowConfig {
  agent: string
  environments: UneeqCoreDialogflowEnvironment[]
}

export interface UneeqCoreNLP {
  dialogflow?: UneeqCoreDialogflowConfig
  statworks?: string
}

export interface PersonaFeatures {
  historyNavigation?: boolean
  buttonOnly: boolean
}
export interface UneeqCorePersonaProfile {
  name?: string
  sendLocalAudio: boolean
  uneeq?: UneeqCorePersonaServer
  features: PersonaFeatures
  style: UneeqCorePersonaStyle
}

export interface UneeqCoreConfig {
  id: number
  name: string
  debug: boolean
  server: string
  nlp: UneeqCoreNLP
  languages: string[] // Could be create a enum in the future
  persona: UneeqCorePersonaProfile
  sendLocalVideo: boolean
  sendLocalAudio: boolean
  customData: any

  //ToDo - Verify the items below if we are really using it.
  usePasscode: boolean
  playWelcome: boolean
  informationInTranscript: boolean
  tapThreshold: number
  timeout: number
  timeoutWarning: number
  emptyTranscriptThreshold: number
  analytics?: EventHandler
  errorLevels: {}
}

// state slice to close all modals
export const closeModals = {
  menuOpen: false,
  settingsOpen: false,
  endConfirmOpen: false,
  feedbackOpen: false,
  escalationFormOpen: false,
  escalationFormFromServer: false,
  timeoutOpen: false,
  privacyOpen: false,
  error: null,
  supportFeedbackOpen: false
}

// state slice to close all dialogs
export const closeDialogs = {
  onScreenInfo: {},
  expandedInfo: null,
  question: null,
  transcriptOpen: false,
  noInput: false,
  error: null,
  galleryOpen: false
}

type StateConfig = Pick<UneeqCoreConfig, 'timeout'>

export const initialState = (config: StateConfig) => ({
  ready: false,
  selectedDevices: {
    videoInput: localStorage.getItem('videoInput'),
    audioInput: localStorage.getItem('audioInput'),
    audioOutput: localStorage.getItem('audioOutput')
  },
  selectedButtonsId: [],
  question: null,
  inputMode: 'speech',
  buttonOnly: false,
  fullAvatarMode: false,
  awaitingResponse: false,
  timedOut: false,
  sessionEnded: false,
  selectedSavedItem: null,
  hiddenUI: false,
  mediaOpen: false,
  captionVisible: captionVisible,
  avatarSpeaking: false,
  sending: false,
  currentMessage: '',
  recording: false,
  lastActivity: Date.now(),
  timeLeft: config.timeout,
  permissionAllowed: undefined,
  transcript: [],
  transcriptHasOpened: false,
  mobileInformationOpen: false,
  feedbackGiven: false,
  contactDetailsGiven: false,
  typeModeFromBackend: false,
  savedItems: [],
  galleryIndex: 0,
  videoOpen: false,
  videoFullscreen: false,
  videoIndex: 0,
  imageGallery: new Map(),
  videoGallery: new Map(),
  loadingPercentage: 0,
  emptyTranscriptCount: 0,
  remSize: 0,
  screenZoom: 100,
  sessionID: '',
  ...closeModals,
  ...closeDialogs,
  ...(testState || {})
})

export default initialState
