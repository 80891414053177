import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { Box, Flex, Text, Image } from 'rebass'
import { UneeqContext, useTranscript, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import TranscriptFooter from './TranscriptFooter'
import TranscriptMessage from './TranscriptMessage'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { InformationItem } from '../OnScreenInfo/InformationTypes'
import { useMediaQuery } from 'react-responsive'
const MotionBox: any = motion.custom(Box)

export interface TranscriptItem {
  information?: InformationItem[]
  message?: string
  user?: boolean
  time: Date
  type?: string
}

const Transcript: React.FC = () => {
  const { config } = useContext(UneeqContext)
  const { dispatch } = useContext(UneeqContext)
  const { transcriptOpen, inputMode } = useUneeqState()
  const { downloadTranscript, transcript } = useTranscript()
  const hasText = useRef<boolean>()
  const { t } = useTranslation()

  // autoscroll to bottom
  const scrollArea = useRef<HTMLElement>()
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const [scroll, setScroll] = useState('scrollTop')

  const getScrollPosition = () => {
    if (scrollArea && scrollArea.current) {
      const scrollPosition = scrollArea.current.scrollTop
      if (scrollPosition == 0) return 'scrollTop'
      const result =
        scrollArea.current?.scrollHeight -
        scrollArea.current?.clientHeight -
        scrollArea.current?.scrollTop
      if (result < 0) return 'scrollBot'
      return 'scrollMid'
    }
    return undefined
  }

  const handleFadeScroll = () => {
    if (scrollArea && scrollArea.current) {
      let Style = getScrollPosition()
      if (Style !== undefined) setScroll(Style)
    }
  }
  useLayoutEffect(() => {
    if (scrollArea && scrollArea.current) {
      let scrollPercentage = sessionStorage.getItem('scrollPercentage')
      if (scrollPercentage !== null) {
        scrollArea.current.scrollTop =
          (scrollArea.current.scrollHeight - scrollArea.current.clientHeight) *
          Number(sessionStorage.getItem('scrollPercentage'))
      }
    }
  }, [mediaQueryMobile])
  const handleScroll = () => {
    if (scrollArea && scrollArea.current) {
      const position = scrollArea.current?.scrollTop
      const percentage =
        position /
        (scrollArea.current?.scrollHeight - scrollArea.current?.clientHeight)
      sessionStorage.setItem('scrollPercentage', percentage.toString())
    }
  }
  useEffect(() => {
    if (scrollArea && scrollArea.current) {
      scrollArea.current.addEventListener('scroll', handleScroll, {
        passive: true
      })
      const node = scrollArea.current
      return () => {
        if (node) node.removeEventListener('scroll', handleScroll)
      }
    }
  }, [mediaQueryMobile, scrollArea?.current])
  useEffect(() => {
    if (scrollArea && scrollArea.current) {
      scrollArea.current.scrollTop =
        scrollArea?.current.scrollHeight - scrollArea?.current.clientHeight
    }
  }, [transcriptOpen, transcript])

  const close = () => {
    dispatch({ type: 'setInputMode', payload: 'speech' })
    dispatch({ type: 'closeTranscript' })
  }

  const width = window.screen.availWidth
  const speechMode = inputMode === 'speech'
  const isChatEnabled = !speechMode && mediaQueryMobile
  const getOffsetHeight = () => {
    if (scrollArea && scrollArea.current) {
      return Math.floor(scrollArea.current.offsetHeight * 0.12)
    }
    return 0
  }
  return (
    <AnimatePresence>
      {transcriptOpen &&
        (!mediaQueryMobile ? (
          <MotionBox
            initial={{
              opacity: 1,
              transform: `translate(${width * 1.5}px,0px)`
            }}
            animate={{
              opacity: 1,
              transform: `translate(0px, 0px)`
            }}
            exit={{
              opacity: 1,
              transform: `translate(${width * 1.5}px,0px)`
            }}
            sx={styles.container}
          >
            {/* <TranscriptTopBar /> */}
            <Flex
              sx={
                isChatEnabled
                  ? styles.transcriptOverlayMobile
                  : styles.transcriptOverlay
              }
            ></Flex>
            <Flex variant="transcript" sx={styles.transcript}>
              <Flex
                ref={scrollArea}
                onScroll={handleFadeScroll}
                sx={{
                  ...styles.scrollArea,
                  maskImage:
                    scroll == 'scrollTop'
                      ? 'linear-gradient(to top, transparent 0%, black 2.5rem)'
                      : scroll == 'scrollMid'
                      ? 'linear-gradient(to top, transparent 0%, black 2.5rem, black calc(100% - 5rem), transparent 100%)'
                      : 'linear-gradient(to top, black 0%, black calc(100% - 5rem), transparent 100%)',
                  WebkitMaskImage:
                    scroll == 'scrollTop'
                      ? 'linear-gradient(to top, transparent 0%, black 2.5rem)'
                      : scroll == 'scrollMid'
                      ? 'linear-gradient(to top, transparent 0%, black 2.5rem, black calc(100% - 5rem), transparent 100%)'
                      : 'linear-gradient(to top, black 0%, black calc(100% - 5rem), transparent 100%)',
                  '&::-webkit-scrollbar-track': {
                    width: '0.25rem'
                  }
                }}
                className="mask"
              >
                <Box sx={styles.scrollContent}>
                  {transcript.length ? (
                    transcript.map((line: TranscriptItem) => {
                      if (
                        line?.information &&
                        line?.information[0]['buttons']
                      ) {
                        line.information[0]['buttons'] = line.information[0][
                          'buttons'
                        ].filter(button => !button?.label?.includes('GO_'))
                        if (line.information[0]['buttons'].length > 0)
                          return (
                            <TranscriptMessage
                              key={line.time.valueOf()}
                              line={line}
                            />
                          )
                        else return null
                      } else
                        return (
                          <TranscriptMessage
                            key={line.time.valueOf()}
                            line={line}
                          />
                        )
                    })
                  ) : (
                    <Text>{t('Transcript.noTranscriptAvailable')}</Text>
                  )}
                </Box>
              </Flex>
              {config.persona.features?.buttonOnly ||
              config.persona.features?.footerDisabled ? (
                ''
              ) : (
                <Flex>
                  <TranscriptFooter
                    setHasText={(has: boolean) => {
                      hasText.current = has
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </MotionBox>
        ) : (
          <MotionBox
            initial={{
              opacity: 1,
              transform: `translate(${width * 1.5}px,0px)`
            }}
            animate={{
              opacity: 1,
              transform: `translate(0px, 0px)`
            }}
            exit={{
              opacity: 1,
              transform: `translate(${width * 1.5}px,0px)`
            }}
            sx={styles.container}
          >
            {/* <TranscriptTopBar /> */}
            <Flex variant="transcript" sx={styles.mobileTranscript}>
              <Flex
                ref={scrollArea}
                sx={{
                  ...styles.scrollArea,
                  maskImage:
                    scroll == 'scrollTop'
                      ? 'linear-gradient(to top, transparent 0%, black 2.5rem)'
                      : scroll == 'scrollMid'
                      ? 'linear-gradient(to top, transparent 0%, black 2.5rem, black calc(100% - 5rem), transparent 100%)'
                      : 'linear-gradient(to top, black 0%, black calc(100% - 5rem), transparent 100%)',
                  WebkitMaskImage:
                    scroll == 'scrollTop'
                      ? 'linear-gradient(to top, transparent 0%, black 2.5rem)'
                      : scroll == 'scrollMid'
                      ? 'linear-gradient(to top, transparent 0%, black 2.5rem, black calc(100% - 5rem), transparent 100%)'
                      : 'linear-gradient(to top, black 0%, black calc(100% - 5rem), transparent 100%)'
                }}
                onScroll={handleFadeScroll}
              >
                <Box sx={styles.mobileScrollContent}>
                  {transcript.length ? (
                    transcript.map((line: TranscriptItem) => {
                      return (
                        <TranscriptMessage
                          key={line.time.valueOf()}
                          line={line}
                        />
                      )
                    })
                  ) : (
                    <Text>{t('Transcript.noTranscriptAvailable')}</Text>
                  )}
                </Box>
              </Flex>
              {config.persona.features?.buttonOnly ||
              config.persona.features?.footerDisabled ? (
                ''
              ) : (
                <Flex>
                  <TranscriptFooter
                    setHasText={(has: boolean) => {
                      hasText.current = has
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </MotionBox>
        ))}
    </AnimatePresence>
  )
}

export default Transcript
