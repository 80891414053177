import React, { useContext, useRef } from 'react'
import { Box, Flex } from 'rebass'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { ReactComponent as ChatIcon } from '../../assets/img/conversation2.svg'
import { ReactComponent as HistoryMode } from '../../assets/img/history-mode.svg'
import AnimatedFeedback from '../AnimatedFeedback/AnimatedFeedback'
import styles from './styles'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { useTranslation } from 'react-i18next'
import Config from 'digital-human/src/config'
import { useMediaQuery } from 'react-responsive'
import Menu from '../Menu'

interface ModalityToggleProps {
  isDisabled: boolean
  isChatEnabled?: boolean
  speechMode: boolean
  modalityToggleMode: () => void
}
const ModalityToggle: React.FC<ModalityToggleProps> = ({
  speechMode,
  modalityToggleMode
}) => {
  const { t } = useTranslation()
  const config = Config.getInstance()
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width:768px)'
  })
  const { galleryOpen } = useUneeqState()
  const iconChatStyle = !galleryOpen
    ? speechMode
      ? config.persona.style.colors.icons.chat.basic
      : config.persona.style.colors.icons.chat.active
    : config?.persona.style.colors.galleryMode
  const chatColor = config.persona.style.colors.icons.chat.mobile
    ? config.persona.style.colors.icons.chat.mobile
    : iconChatStyle

  return (
    <Flex
      sx={
        mediaQueryMobile
          ? {
              ...styles.topBarButton,
              ...styles.topBarButtonChat,
              ...chatColor,
              boxShadow: 'none !important'
            }
          : {
              ...styles.topBarButton,
              ...styles.topBarButtonChat,
              ...iconChatStyle,
              boxShadow: 'none !important'
            }
      }
      onClick={modalityToggleMode}
    >
      <ChatIcon />
    </Flex>
  )
}
interface RestartProps {
  restart: () => void
}
const TopBar: React.FC<RestartProps> = ({ restart }) => {
  let {
    inputMode,
    transcriptOpen,
    menuOpen,
    galleryOpen,
    fullAvatarMode
  } = useUneeqState()
  const { state } = useContext(UneeqContext)

  const isDisabled = () =>
    state.recording || state.avatarSpeaking || state.sending

  const { dispatch, config } = useContext(UneeqContext)
  const close = () => {
    dispatch({ type: 'setInputMode', payload: 'speech' })
    dispatch({ type: 'closeTranscript' })
  }
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width:768px)'
  })
  const mediaQueryExtraSmall = useMediaQuery({
    query: '(max-width:330px)'
  })
  const speechMode = inputMode === 'speech'
  const isChatEnabled = !speechMode && mediaQueryMobile

  const toggleModalityMode = () => {
    if (speechMode) {
      dispatch({ type: 'setInputMode', payload: 'text' }) |
        dispatch({ type: 'closeGallery' })
      openTranscript()
    } else {
      dispatch({ type: 'setInputMode', payload: 'speech' })
      closeTranscript()
    }
  }

  const openTranscript = () =>
    dispatch({ type: 'openTranscript', payload: true })
  const closeTranscript = () => dispatch({ type: 'closeTranscript' })
  const inputModeContainer = useRef()
  useOnClickOutside(inputModeContainer, toggleModalityMode)

  return (
    <Flex sx={styles.bar}>
      {config.persona.features?.buttonOnly ? (
        <Flex sx={styles.topBarFlexContainer}>
          {!transcriptOpen ? (
            <Box
              sx={{
                ...styles.topBarMobileButtonContainer,
                '& > svg': {
                  fill: galleryOpen
                    ? config.persona.style.colors.galleryMode?.fill
                    : !speechMode
                    ? config.persona.style.colors.icons.chat.active.fill +
                      ' !important'
                    : config.persona.style.colors.icons.chat.basic.fill +
                      ' !important'
                }
              }}
              onClick={() => {
                if (speechMode) {
                  dispatch({ type: 'setInputMode', payload: 'text' })
                  dispatch({ type: 'closeGallery' })
                  openTranscript()
                } else {
                  dispatch({ type: 'setInputMode', payload: 'speech' })
                  closeTranscript()
                }
              }}
            >
              <HistoryMode width="2rem" height="2rem" />
            </Box>
          ) : (
            <Box sx={styles.avatarIcon} onClick={close}></Box>
          )}
          <AnimatedFeedback />
          <Box
            sx={{
              ...styles.topBarMobileButtonContainer,
              ...styles.topBarSettingsContainer,
              paddingTop: '0rem'
            }}
          >
            <Menu restart={restart} />
          </Box>
        </Flex>
      ) : config.persona.name === 'Diana' ? (
        <Flex
          sx={{ ...styles.topBarFlexContainerDiana, marginLeft: '6.25rem' }}
        >
          <Flex sx={{ ...styles.dianaFeedbackContainer }}>
            {mediaQueryMobile &&
              (config.persona.name !== 'Diana' || !fullAvatarMode) && (
                <AnimatedFeedback />
              )}
          </Flex>
          <Box
            sx={
              mediaQueryMobile
                ? {
                    ...styles.topBarMobileButtonContainer,
                    ...styles.topBarSettingsContainer,
                    marginLeft: '6.25rem',
                    marginRight: '-1.25rem',
                    display: 'flex'
                  }
                : { display: 'block' }
            }
          >
            <Menu restart={restart} />
          </Box>
        </Flex>
      ) : (
        <Flex sx={styles.topBarFlexContainer}>
          {!transcriptOpen ? (
            <Box
              sx={{
                ...styles.topBarButtonContainer,
                cursor: 'auto'
              }}
            >
              {!isChatEnabled && (
                <ModalityToggle
                  isDisabled={isDisabled()}
                  speechMode={speechMode}
                  modalityToggleMode={trackHandler(
                    toggleModalityMode,
                    speechMode
                      ? 'enable-type-mode-btn'
                      : 'disable-type-mode-btn'
                  )}
                />
              )}
            </Box>
          ) : (
            <Box sx={styles.avatarIcon} onClick={close}></Box>
          )}
          <AnimatedFeedback />
          <Box
            sx={{
              ...styles.topBarMobileButtonContainer,
              ...styles.topBarSettingsContainer
            }}
          >
            <Menu restart={restart} />
          </Box>
        </Flex>
      )}
    </Flex>
  )
}

export default TopBar
